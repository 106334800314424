<template>
  <div class="container page">
    <van-nav-bar :title="$t('infomation.title')" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()"/>
      </template>
    </van-nav-bar>
    <div class="main-content">

      <!-- 用户头像选择区域 -->
      <div class="item van-hairline--bottom" @click="openHerderImg()">
        <div class="left">{{ $t("infomation.head") }}</div>
        <div class="right">
          <img :src="this.userInfo.header_img">
          <van-icon name="arrow" />
        </div>
      </div>

      <!-- 弹出框中的头像选择区域 -->
      <van-popup v-model="show" position="bottom" round :style="{ height: '50%' }">
        <div class="avatarbox">
          <div class="title van-hairline--bottom">
            <van-icon @click="show=false" name="cross" />
            <div class="text">{{ $t("infomation.select") }}</div>
            <div class="btnok" @click="check()">{{ $t("msg.确定") }}</div>
          </div>
          <div class="content">
            <van-image
                round
                v-for="(item, index) in avatars" :key="index"
                @click="select_header_img(item)"
                :class="{ 'choose': isActive === item }"
                :src="item"
            />
          </div>
        </div>
      </van-popup>

      <!-- 其他用户信息设置项 -->
      <div class="item van-hairline--bottom" @click="toSetName()">
        <div class="left">{{ $t("infomation.name") }}</div>
        <div class="right">
          <span class="desc">{{this.userInfo.name ? this.userInfo.name :$t('msg.未设置')}}</span>
          <van-icon name="arrow" />
        </div>
      </div>

      <div class="item van-hairline--bottom" @click="toSetSex()">
        <div class="left">{{ $t("infomation.sex") }}</div>
        <div class="right">
          <span class="desc">{{this.userInfo.sex !== "0" ? this.userInfo.sex === "1" ? $t("infomation.man") :$t("infomation.girl"): $t("infomation.unknown")}}</span>
          <van-icon name="arrow" />
        </div>
      </div>

      <div class="item van-hairline--bottom" @click="toSetBank()">
        <div class="left">{{ $t("infomation.binding") }}</div>
        <div class="right">
          <span class="desc">{{this.isBank ? $t("msg.已绑定") :$t("msg.无")}}</span>
          <van-icon name="arrow" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isActive: false,
      show: false,
      isBank: false,
      userInfo: {},
      avatars: [] // 存储头像URL的数组
    };
  },
  methods: {
    back() {
      return window.history.back();
    },
    toSetName() {
      this.$router.push({ path: '/Setname' });
    },
    toSetBank() {
      this.$router.push({ path: '/Setbank' });
    },
    toSetSex() {
      this.$router.push({ path: '/Setsex' });
    },
    openHerderImg() {
      this.show = true;
    },
    select_header_img(url) {
      this.isActive = url;
    },
    check() {
      this.$http({
        method: 'post',
        url: 'user_header_img', // 后端接口的 URL
        headers: {
          'Content-Type': 'application/json' // 设置请求头为 JSON 格式
        },
        data: JSON.stringify({ header_img: this.isActive }) // 确保数据是 JSON 格式，手动将数据转换为字符串
      }).then(res => {
        console.log("返回的消息:", res.msg); // 日志调试
        if (res.code === 200) {
          this.getUserInfo(); // 提交成功后更新用户信息
          this.show = false; // 关闭弹出框
        }
		this.$toast(this.$t("msg.更换头像成功！"));
        //this.$toast(this.$t("msg." + res.msg)); // 显示返回的消息提示
      });
    },
    getUserInfo() {
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res => {
        if (res.code === 200) {
          this.userInfo = res.data;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    },
    getUserBankInfo() {
      this.$http({
        method: 'get',
        url: 'user_get_bank'
      }).then(res => {
        if (res.code === 200) {
          this.isBank = res.data.is_bank ? true : false;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    },
    generateAvatars() {
      // 使用 Lorem Picsum API 生成 10 个随机人物头像 URL
      for (let i = 0; i < 20; i++) {
        const seed = Math.random().toString(36).substring(7); // 随机生成一个字符串
        const url = `https://picsum.photos/seed/${seed}/100`; // 使用随机 seed 生成不同的头像
        this.avatars.push(url);
      }
    }
  },
  created() {
    if (!localStorage.getItem('token')) {
      this.$router.push({ path: '/Login' });
    } else {
      this.getUserInfo();
      this.getUserBankInfo();
      this.generateAvatars(); // 创建组件时生成头像 URL
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
.container .main-content {
  padding: 0 20px;
  background-color: #fff;
}
.container .main-content .item{
  padding: 30px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 30px;
}
.container .main-content .item .right{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.container .main-content .item .right img{
  width: 90px;
}
.container .main-content .van-hairline--bottom::after {
  border-bottom-width: 3px;
}
.container .main-content .item .right .desc-cell-number, .container .main-content .item .right .desc{
  font-size: 30px;
  font-weight: 700;
  color: #979799;
}
.avatarbox{
  padding: 15px;
  color: #000;
  height: 81%;
  background-color: #fff;
}
.avatarbox .title{
  padding: 8px 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 28px;
}

.avatarbox .content .van-image{
  width: 105px;
  height: 105px;
  margin: 2.5%;
  border-radius: 50%;
}
.avatarbox .content{
  padding-bottom: 10px;
  height: 100%;
  overflow-y: auto;
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.avatarbox .content .choose{
  width: 95px;
  height: 95px;
  border: 6px solid #e6c3a1;
}
</style>
